import React, { useState, useEffect, useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { Formik, Form, Field } from "formik";
import { useCTContactInforAccess } from "../../hooks/useCTContactInforAccess";
import CareCoordinationTeam from "./CareCoordinationTeam";
import CareCoordinationRequestHistory from "./CareCoordinationRequestHistory";
import {
  paginationWithoutApiCall,
  pagination,
} from "../Common-Health-Records/Helper";
import { SpinnerContext } from "../../contexts/spinner.context";
import { AppContext } from "../../contexts/app.context";
import {
  TX,
  NM,
  NM_MEDICAID_ProgramID,
  MEDICAID,
} from "../../define.constants";

const CTContactInfoAccess = (props) => {
  const [fetchData, setFetchData] = useState(true);
  const {
    careCoordinationHistory,
    careCoordinationTX,
    ctInfoAccessList,
    totalRecords,
    requestingEntityList,
    careTeamFullObj,
    totalHistoryRecords,
    isSubmitAllowed,
    refreshAPIDataAll,
  } = useCTContactInforAccess(fetchData);

  const { innerSpinnerHTML } = useContext(SpinnerContext);
  const { MemberStateName, programId, memberLOB } = useContext(AppContext);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [filteredHistoryRecords, setFilteredHistoryRecords] = useState([]);
  const [requestingEntity, setRequestingEntityList] = useState([]);
  const [careTeamFullData, setCareTeamFullData] = useState([]);
  const recordsPerPage = parseInt(props.fields.lblPostPerPage.value);
  const [next, setNext] = useState(recordsPerPage);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0);
  const [totalHistoryRecordsCount, setTotalHistoryRecordsCount] = useState(0);
  const [slicedRecords, setSlicedRecords] = useState(null);
  const [slicedHistoryRecords, setSlicedHistoryRecords] = useState(null);
  const [postsPerPage, setPostsPerPage] = useState(1);
  const [requestBtnClickStatus, setRequestBtnClickStatus] = useState(false);
  const [cancelBtnClicked, setCancelBtnData] = useState(false);
  const [careTeamPageLoaded, setCareTeamLoadStatus] = useState(true);
  const [careTeamPageLoaded1, setCareTeamLoadStatus1] = useState(null);
  let loadCareTeamPage = true;

  const isNMMedicaid =
    memberLOB?.toLowerCase() === MEDICAID &&
    MemberStateName === NM &&
    programId === NM_MEDICAID_ProgramID;

  useEffect(() => {
    if (MemberStateName === "TX" || isNMMedicaid) {
      refreshAPIDataAll();
      setFilteredHistoryRecords(careCoordinationTX);
    }
  }, [cancelBtnClicked]);

  useEffect(() => {
    setCareTeamFullData(careTeamFullObj);
  }, [careTeamFullObj]);

  useEffect(() => {
    setFilteredRecords(ctInfoAccessList);
  }, [ctInfoAccessList]);
  // TX Medicaid Star plus
  useEffect(() => {
    if (MemberStateName == TX || isNMMedicaid) {
      setFilteredHistoryRecords(careCoordinationTX);
    } else setFilteredHistoryRecords(careCoordinationHistory);
  }, [careCoordinationHistory, careCoordinationTX]);

  useEffect(() => {
    setRequestingEntityList(requestingEntityList);
  }, [requestingEntityList]);

  //START CT Info - Logic to show next set of recods on click of Show More
  useEffect(() => {
    if (totalRecords > 0) {
      setFilteredRecords((r) =>
        page > 1 ? [...r, ...ctInfoAccessList] : ctInfoAccessList
      );
      setTotalRecordsCount(totalRecords);
    }
  }, [ctInfoAccessList, totalRecords, page]);

  useEffect(() => {
    let slicedRecords = filteredRecords;
    const offset = (currentPage - 1) * recordsPerPage;
    slicedRecords = filteredRecords?.slice(0, offset + recordsPerPage);
    if (totalRecordsCount - offset < recordsPerPage) {
      slicedRecords = filteredRecords?.slice(
        0,
        offset + (totalRecordsCount - offset)
      );
    } else {
      slicedRecords = filteredRecords?.slice(0, offset + recordsPerPage);
    }

    setSlicedRecords(slicedRecords);
  }, [filteredRecords, currentPage, totalRecordsCount]);

  let totalPages = Math.ceil(totalRecordsCount / recordsPerPage);

  const resetPage = () => {
    setPage(1);
    setCurrentPage(1);
    setNext(recordsPerPage);
    setUserAction(null);
  };
  //END - Logic to show next set of recods on click of Show More

  //START Request History - Logic to show next set of recods on click of Show More
  useEffect(() => {
    if (totalHistoryRecords > 0) {
      if (MemberStateName == TX || MemberStateName == NM) {
        setFilteredHistoryRecords((r) =>
          page > 1 ? [...r, ...careCoordinationTX] : careCoordinationTX
        );
      } else {
        setFilteredHistoryRecords((r) =>
          page > 1
            ? [...r, ...careCoordinationHistory]
            : careCoordinationHistory
        );
      }
      setTotalHistoryRecordsCount(totalHistoryRecords);
    }
  }, [careCoordinationHistory, careCoordinationTX, totalHistoryRecords, page]);

  useEffect(() => {
    let slicedHistoryRecords = filteredHistoryRecords;
    const offset = (currentPage - 1) * recordsPerPage;
    slicedHistoryRecords = filteredHistoryRecords?.slice(
      0,
      offset + recordsPerPage
    );
    if (totalHistoryRecordsCount - offset < recordsPerPage) {
      slicedHistoryRecords = filteredHistoryRecords?.slice(
        0,
        offset + (totalHistoryRecordsCount - offset)
      );
    } else {
      slicedHistoryRecords = filteredHistoryRecords?.slice(
        0,
        offset + recordsPerPage
      );
    }

    setSlicedHistoryRecords(slicedHistoryRecords);
  }, [filteredHistoryRecords, currentPage, totalHistoryRecordsCount]);
  //TX Medicaid Star Plus
  useEffect(() => {
    let careCoordinationTX = filteredHistoryRecords;
    const offset = (currentPage - 1) * recordsPerPage;
    careCoordinationTX = filteredHistoryRecords?.slice(
      0,
      offset + recordsPerPage
    );
    if (totalHistoryRecordsCount - offset < recordsPerPage) {
      careCoordinationTX = filteredHistoryRecords?.slice(
        0,
        offset + (totalHistoryRecordsCount - offset)
      );
    } else {
      careCoordinationTX = filteredHistoryRecords?.slice(
        0,
        offset + recordsPerPage
      );
    }
    console.log(careCoordinationTX);
    setCareTeamLoadStatus1(careCoordinationTX);
  }, [filteredHistoryRecords, currentPage, totalHistoryRecordsCount]);

  let totalPages2 = Math.ceil(totalHistoryRecordsCount / recordsPerPage);

  const resetPage1 = () => {
    setPage(1);
    setCurrentPage(1);
    setNext(recordsPerPage);
    setUserAction(null);
  };
  //END - Logic to show next set of recods on click of Show More

  //This index.js page renders both CareTEam, History grids so identify whether
  //request care coordination button is clicked in child component
  //and disable history gird . History grid needed only in main page
  const handleRequestBtnClick = (isRequestBtnClicked) => {
    setRequestBtnClickStatus(isRequestBtnClicked); //true will come from RCC page
    setCareTeamLoadStatus(false);
    setCancelBtnData(false);
  };

  //when cancel button is clicked in RCC page, set below conditions so that
  //care coordination History grid will not load
  const handleCancelCallBack = (propFromRCC) => {
    setCancelBtnData(propFromRCC);
    setRequestBtnClickStatus(false);
    setCareTeamLoadStatus(true);
    //setCareTeamLoadStatus1(careCoordinationTX);
  };
  const TXPageLoaded = careTeamPageLoaded1 ? true : false;
  console.log(careTeamPageLoaded);
  const paginationLabel = (totalHistoryRecords, next, props) => {
    return (
      // <div className="col-12 col-lg-3" style={{ display: "inline-block" }}>
      <span className="pb-3">
        <br></br>
        <br></br>
        {totalHistoryRecords < props.lblPostPerPage.value ? (
          <label>
            {totalHistoryRecords} <Text field={props.lblResults} />
          </label>
        ) : (
          <label>
            1-{next} <Text field={props.lblPrep} /> {totalHistoryRecords}{" "}
            <Text field={props.lblResults} />
          </label>
        )}
      </span>
      // </div>
    );
  };

  return (
    <>
      <Formik>
        <div
          className="tab-pane fade show active referrals-tab"
          id="referrals"
          role="tabpanel"
          aria-label="tab-1"
        >
          <Form>
            <div className="row no-tab-title" id="medicationDate">
              <div className="col-12 no-date-filter">
                <>
                  {/* show the page at all time so called the component without 
                any condition. Grid to show or not inside the component is decidede 
                inside the below component */}
                  {cancelBtnClicked || careTeamPageLoaded}
                  <CareCoordinationTeam
                    fields={props.fields}
                    data={slicedRecords}
                    data1={careTeamFullObj}
                    parentCallback={handleRequestBtnClick} //Identify whethere Request care coordination button [in child component] is clicked or not
                    cancelCallBackInRCC={handleCancelCallBack} //If cancel is clicked in Request care coordination page
                    isSubmitAllowed={isSubmitAllowed}
                  />
                  {/* Show more option to show only when there is any record in grid */}
                  {slicedRecords != null &&
                    slicedRecords.length > 0 &&
                    totalPages !== page && (
                      <a
                        className="showMore"
                        onClick={(e) =>
                          pagination(
                            totalRecordsCount,
                            next,
                            page,
                            recordsPerPage,
                            setNext,
                            setPage,
                            currentPage,
                            setCurrentPage,
                            200,
                            setFetchData
                          )
                        }
                      >
                        {(!requestBtnClickStatus && careTeamPageLoaded) ||
                        cancelBtnClicked ? (
                          <>
                            <Text field={props.fields.lblShowMore}></Text>
                          </>
                        ) : (
                          ""
                        )}
                      </a>
                    )}
                </>
                {(!requestBtnClickStatus &&
                  (MemberStateName == TX || isNMMedicaid
                    ? TXPageLoaded
                    : careTeamPageLoaded)) ||
                cancelBtnClicked ? (
                  <>
                    {MemberStateName !== TX && !isNMMedicaid && (
                      <div className="me-auto p-2" style={{ marginTop: "-5%" }}>
                        <div className="ohio-subheading">
                          <Text field={props.fields.TitleHistory} />
                        </div>
                      </div>
                    )}
                    {(MemberStateName == TX || isNMMedicaid) &&
                      totalHistoryRecordsCount > 0 &&
                      !requestBtnClickStatus &&
                      paginationLabel(
                        totalHistoryRecordsCount,
                        next,
                        props.fields
                      )}

                    <CareCoordinationRequestHistory
                      fields={props.fields}
                      data={
                        MemberStateName != TX || MemberStateName != NM
                          ? slicedHistoryRecords
                          : careTeamPageLoaded1
                      }
                    />
                    {slicedHistoryRecords != null &&
                      slicedHistoryRecords.length > 0 &&
                      totalPages2 !== page &&
                      (MemberStateName != TX || MemberStateName != NM) && (
                        <a
                          className="showMore"
                          onClick={(e) =>
                            pagination(
                              totalHistoryRecordsCount,
                              next,
                              page,
                              recordsPerPage,
                              setNext,
                              setPage,
                              currentPage,
                              setCurrentPage,
                              200,
                              setFetchData
                            )
                          }
                        >
                          {(!requestBtnClickStatus && careTeamPageLoaded) ||
                          cancelBtnClicked ? (
                            <>
                              <Text field={props.fields.lblShowMore}></Text>
                            </>
                          ) : (
                            ""
                          )}
                        </a>
                      )}
                    {careCoordinationTX != null &&
                      careCoordinationTX.length > 0 &&
                      totalPages2 !== currentPage &&
                      (MemberStateName == TX || isNMMedicaid) && (
                        <a
                          className="showMore hr-contact"
                          onClick={(e) =>
                            pagination(
                              totalHistoryRecordsCount,
                              next,
                              page,
                              recordsPerPage,
                              setNext,
                              setPage,
                              currentPage,
                              setCurrentPage,
                              200,
                              setFetchData
                            )
                          }
                        >
                          {(!requestBtnClickStatus &&
                            (MemberStateName == TX || MemberStateName == NM
                              ? TXPageLoaded
                              : careTeamPageLoaded)) ||
                          cancelBtnClicked ? (
                            <>
                              <Text field={props.fields.lblShowMore}></Text>
                            </>
                          ) : (
                            ""
                          )}
                        </a>
                      )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Formik>
    </>
  );
};

export default CTContactInfoAccess;
